<template>
    <div class="zt-page-content" ref="load">
        <div v-if="item" class="container flex">
            <div class="detail-left flex-1">
                <div class="detail-top">
                    <div class="top flex">
                        <div class="info-img">
                            <img src="@/assets/images/desgin/icon_design.png">
                        </div>
                        <div class="detail-info flex-1">
                            <div class="info-name ellipsis1">
                                <b>{{item.name}}</b>
                            </div>
                            <div class="info-design_idea">
                                设计理念：{{item.design_idea || '暂无设计理念'}}
                            </div>
                            <div v-if="item.dimension" class="info-spec flex">
                                设计规格：
                                <span v-if="item.dimension && item.dimension.length>0" class="flex">
                                    <label v-for="(spec,idx) in item.dimension" :key="idx">{{spec}}</label>
                                </span>
                                <span v-else>暂无设计规格</span>
                            </div>
                            <div class="info-desc">
                                作品描述：{{item.intro || '暂无作品概述'}}
                            </div>
                        </div>
                    </div>
                    <div class="bottom flex">
                        <div v-if="item.style" class="flex flex-align-center">
                            <img src="@/assets/images/desgin/icon_style.png">
                            <span>{{item.style.name}}</span>
                        </div>
                        <div v-if="item.cat" class="flex flex-align-center">
                            <img src="@/assets/images/desgin/icon_cat.png">
                            <span>{{item.cat.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="detail-bottom">
                    <div class="tab_menu flex">
                        <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="menu_item" :class="{'cur':tabIndex == index}">
                            {{item.name}}
                        </div>
                    </div>
                    <div class="db-box">
                        <div v-if="tabIndex==0">
                            <div v-if="item.photo_render && item.photo_render.length>0">
                                <img v-for="(item,idx) in item.photo_render" :key="idx" 
                                    :src="(IMG_URL_PRE || '')+item+'!width_800px'" alt="">
                            </div>
                            <div v-else>
                                <img src="@/assets/images/desgin/zwt.png">
                            </div>
                        </div>
                        <div v-if="tabIndex==1">
                            <div v-if="item.photo_size && item.photo_size.length>0">
                                <img v-for="(item,idx) in item.photo_size" :key="idx" 
                                    :src="(IMG_URL_PRE || '')+item+'!width_800px'" alt="">
                            </div>
                            <div v-else>
                                <img src="@/assets/images/desgin/zwt.png">
                            </div>
                        </div>
                        <div v-if="tabIndex==2">
                            <div v-if="item.photo_story && item.photo_story.length>0">
                                <img v-for="(item,idx) in item.photo_story" :key="idx" 
                                    :src="(IMG_URL_PRE || '')+item+'!width_800px'" alt="">
                            </div>
                            <div v-else>
                                <img src="@/assets/images/desgin/zwt.png">
                            </div>
                        </div>
                    </div>
                    <div v-if="item &&item.photo_cad" class="db-cad flex flex-align-center">
                        <div style="margin-right:10px">设计CAD文件：</div>
                        <div class="flex-1 flex flex-align-center">
                            <img src="@/assets/images/desgin/icon_file.png">
                            <div v-if="item && item.trans_stat>=6 && item.photo_cad || setStat>=6 && item.photo_cad" class="text">(共{{item.photo_cad.length}}份)</div>
                            <div v-else class="text">购买后可下载 ({{item.photo_cad.length}})</div>
                            <div style="margin-left: 20px;">
                                <a v-if="item && item.trans_stat>=6 && item.photo_cad.length>0 || setStat>=6 && item.photo_cad.length>0" :href="IMG_URL_PRE+item.photo_cad[0]">
                                    <el-button @click="download()" size="mini" type="primary" round>下载</el-button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="type!=1" class="detail-right">
                <div v-if="!setType" class="dr-info">
                    <div>作品属性：
                        <span v-if="item.price_type==0">设计版权出售</span>
                        <span v-if="item.price_type==1">分成合作</span>
                        <span v-if="item.price_type==3">需求定制</span>
                        <span v-if="item.price_type==4">暂不上架</span>
                    </div>
                    <div v-if="item.price_type==0" class="contract_view">
                        <el-button @click="contract_view(item)" type="text">查看合同</el-button>
                    </div>
                    <div>一口价：
                        <span class="price">￥{{item.price | priceFilt}}</span>
                    </div>
                    <div v-if="item.trans_stat>0">当前状态：
                        <span style="color:#F66F6A;">该作品已被（他人）签约</span>
                    </div>
                    <div v-else>
                        <el-button @click="gotoSign(item)" size="mini" type="primary">立即购买</el-button>
                    </div>
                </div>
                <div v-if="setType" class="dr-info">
                    <div>
                        <el-button @click="back()" size="mini" type="primary">查看套系信息</el-button>
                    </div>
                </div>
                <div class="dr-company flex">
                    <div class="drc-left">
                        <img v-if="item.design_comp && item.design_comp.logo" 
                            :src="IMG_URL_PRE+item.design_comp.logo+'!width_250px'">
                        <img v-else :src="STATIC_URL_PRE+'design/placeholder/zhanweilogo%402x.jpg!width_250px'">
                    </div>
                    <div class="drc-right flex-1">
                        <div v-if="item.design_comp" class="name">
                            <b>{{item.design_comp.name}}</b>
                        </div>
                        <div class="label-wrap flex">
                            <label class="color-1">家具设计</label>
                            <label class="color-2">3D渲染</label>
                            <!-- <Label v-for="(item, i) in compInfo.aptitude.ability_service.range"
                                :key="i" :name="item.v"/> -->
                        </div>
                        <div>
                            <el-button @click="toAptitude(item.design_comp.id)" size="mini" type="primary">查看资质</el-button>
                        </div>
                    </div>
                </div>
                <div v-if="list && list.length>0" class="dr-recommend">
                    <div class="drr-head flex flex-align-center">
                        <img src="@/assets/images/factory/icon_jian.png" alt="">
                        <span>推荐商品</span>
                    </div>
                    <div class="drr-list">
                        <div class="list-item flex" v-for="(item,idx) in list" :key="idx">
                            <div @click="gotoDetail(item.id)" class="list-item-img" v-if="item.photo_render && item.photo_render.length>0">
                                <img :src="IMG_URL_PRE+item.photo_render[0]+'!width_250px'" alt="">
                            </div>
                            <div class="list-item-info flex-1">
                                <div class="name ellipsis2">
                                    <b>{{item.name}}</b>
                                </div>
                                <div class="cat">
                                    <span v-if="item.style">{{item.style.name}}</span>
                                    <span v-if="item.style && item.cat"> | </span>
                                    <span v-if="item.cat">{{item.cat.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog width="500px" @close="refreshItem" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <div v-if="popTitle == '签约'">
                <Sign :popTitle="popTitle" @data="refreshItem" @signState="signState" @hide="hide"></Sign>
            </div>
            <div v-if="popTitle == '遇到问题'">
                <SignBack :popTitle="popTitle" @hide="popVisible=false"></SignBack>
            </div>
            <div v-if="popTitle == '请选择签约人'">
                <SignSelect :popTitle="popTitle" :user_id="seal_user_id" :sealMember="sealMember" @hide="popVisible=false" @success="buy"></SignSelect>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
import { listSealMember, buyProduct,contractAddr,contractView, testHt } from '@/service/contract';
import { marketDetailProduct, marketListProduct } from '@/service/factory';
import Sign from '@/components/sign/sign.vue';
import SignBack from '@/components/sign/signBack.vue';
import SignSelect from '@/components/sign/SignSelect.vue';
export default {
    components: {
        Sign, SignBack, SignSelect
    },
    data() {
        return {
            IMG_URL_PRE,
            popTitle: null,
            popVisible: false,
            STATIC_URL_PRE,
            item: null,
            tabList:[
                { name:"3D单品渲染图" },
                { name:"标高图" },
                { name:"素材图" },
            ],
            tabIndex:0,
            opt: {
                    page: 1,
                    pagesize: 5,
                },
            list:[],
            loading: null,
            type: "", // 1从版权购买订单进来
            setType: "", // 1从套系跳转进来
            seal_user_id: null, // 合同签约人ID
            sealMember: [], // 合同签约人列表
            setStat: null, // 从套系跳转过来-套系的状态
        }
    },
    watch: {
        $route(to,from){
            this.refreshItem();
        }
    },
    created() {
        this.type = this.$route.query.type;
        this.setType = this.$route.query.setType;
        this.setStat = this.$route.query.s;
        this.seal_user_id = this.$store.state.loginUser.user.id;
        this.refreshItem();
        this.getRecomment();
    },
    mounted() {},
    methods: {
        back() {
            this.$router.go(-1);
        },
        gotoDetail(id) { // 查看推荐商品
            this.$router.push("/factory/market/single/detail/"+id);
        },
        toAptitude(id) { // 查看资质
            window.open('/zizhi/view/'+id);
        },
        // 选项卡
        tab(index) {
            this.tabIndex = index;
            console.log(index);
        },
        refreshItem() { // 获取数据
            console.log('refreshItem');
            const _loading = this.$loading({
                lock: true,
                text: '拼命加载中......',
                target: this.$refs.load
            });
            marketDetailProduct(this.$route.params.id).then(res => {
                this.item = res
                console.log(this.item);
                _loading.close();
            }).catch(err => {
                console.log(err);
                this.$message.error(err.message);
                _loading.close();
            });
        },
        getRecomment() {
            marketListProduct(this.opt).then(rst => {
                this.list = rst.list;
                console.log('getRecomment',this.list);
            }).catch(err => {
                console.log(err);
                this.$message.error(err.message);
            });
        },
        contract_view(item) { // 查看合同
            if (item.contract_no) {
                window.open(contractView(item.contract_no));
            } else {
                let obj = {
                    contract_title:"设计版权购买合同",
                    content_file: item.contract_file,
                    custom_cover_page: item.custom_cover_page,
                }
                if (item.price_type==0) {
                    obj.contract_title = "设计版权购买合同";
                } else if (item.price_type==3) {
                    obj.contract_title = "需求定制合同";
                } else {
                    obj.contract_title = "合同标题";
                }
                if (obj.content_file) {
                    window.open(testHt(obj));
                }
            }
        },
        gotoSign() {
            if (this.$store.state.loginUser.comp.cert_stat!=2) {
                this.$message.error("企业未认证");
                return;
            } else {
                this.$confirm('确认是否发起签约?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消',closeOnClickModal: false, type: 'warning',
                }).then(() => {
                    let obj = {
                        seal_type: 1, // 印章类型，0保留，1设计作品版权出售合同章，2商品打样合同章，3代加工合同章
                        page: 1,
                        pagesize: 15
                    }
                    listSealMember(obj).then(res=>{
                        if (res && res.list && res.list.length>0) {
                            this.sealMember = res.list;
                            this.selectSign();
                        } else {
                            this.buy();
                        }
                    }).catch(err=>{
                        this.$message.error(err.message);
                    });
                    // this.buy();
                    
                });
            }
        },
        selectSign() {
            this.popTitle = '请选择签约人';
            this.popVisible = true;
        },
        buy(user_id) {
            console.log(user_id);
            this.loading = this.$loading({
                fullscreen: true, text: '拼命加载中....',
                target:this.$refs.load
            });
            buyProduct(this.item.id).then(rst=>{
                if (user_id) {
                    this.going(rst.contract_no,user_id);
                } else {
                    this.going(rst.contract_no);
                }
            }).catch(err=>{
                this.$message.error(err.message);
                this.loading.close();
            });
        },
        going(contract_no,transaction_no) {
            // let invsec = 30;
            // let timer = setInterval((e) => {
            //     contractAddr(contract_no).then(rst=>{
            //         console.log("rst",rst);
            //         if (rst && rst.url) {
            //             this.sign(rst.url);
            //             clearInterval(timer);
            //             return;
            //         }
            //     }).catch(err=>{
            //         // this.$message.error(err.message);
            //         this.loading.close();
            //     });
            //     // if (invsec == 0) {
            //     //     // this.goCgdd();
            //     //     window.location.href="about:blank";
            //     //     window.close();
            //     //     clearInterval(timer);
            //     //     return;
            //     // }
            //     // invsec--;
            // }, 2000);
            contractAddr(contract_no).then(rst=>{
                console.log("rst",rst);
                if (rst && rst.url) {
                    //激发标签点击事件OVER
                    // let ev = document.createEvent("MouseEvents");
                    // ev.initEvent("click", true, true);
                    // var a = document.getElementById("aHref");
                    // a.href = rst.url;
                    // a.dispatchEvent(ev);
                    window.open(rst.url);
                    this.signState("签约");
                    this.loading.close();
                }
            }).catch(err=>{
                // this.$message.error(err.message);
                this.loading.close();
            });
        },
        hide() {
            this.popVisible = false;
            this.$message.success('状态已更新');
        },
        signState(text) {
            this.popTitle = text;
            this.popVisible = true;
        },
    }
}
</script>
<style scoped src="../../style.css"></style>
<style scoped lang="less">
.container {
    width: 1200px;
    margin: 0 auto;
}
.detail-left {
    margin-right: 20px;
}
.detail-top {
    padding: 20px;
    background: #fff;
    border: 1px solid #E6E6E6;
    margin-bottom: 20px;
    .top {
        .info-img {
            width: 36px;
            height: 36px;
            background: #5074EE;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 12px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .info-name {
            font-size: 18px;
            color: #292929;
            line-height: 25px;
            margin-bottom: 12px;
        }
        .info-design_idea {
            font-size: 14px;
            color: #4D4D4D;
            line-height: 20px;
        }
        .info-spec {
            padding: 16px 0 4px;
            font-size: 14px;
            color: #292929;
            line-height: 26px;
            span {
                font-size: 14px;
                color: #B3B3B3;
                label {
                    padding: 4px 8px;
                    border: 1px solid #CCCCCC;
                    font-size: 12px;
                    color: #808080;
                    line-height: 16px;
                    margin: 0 12px 12px 0;
                }
            }
        }
        .info-desc {
            padding: 12px;
            background: #FAFAFA;
            border: 1px solid #E6E6E6;
            margin-bottom: 20px;
        }
    }
    .bottom {
        padding: 20px 50px 0;
        border-top: 1px solid #E6E6E6;
        >div {
            font-size: 14px;
            color: #4D4D4D;
            margin-right: 45px;
            >img {
                height: 30px;
                margin-right: 12px;
            }
        }
    }
}
.detail-bottom {
    background: #fff;
    border: 1px solid #E6E6E6;
    margin-bottom: 40px;
    .tab_menu {
        padding: 0 20px;
        height: 50px;
        border-bottom: 1px solid #E6E6E6;
        .menu_item {
            height: 50px;
            line-height: 50px;
            margin-right: 40px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            color: #4D4D4D;
            &.cur {
                color: #5074EE;
                border-bottom: 2px solid #5074EE;
            }
        }
    }
    .db-box {
        width: 750px;
        margin: 0 auto;
        padding: 40px 0 20px;
        img {
            width: 100%;
            margin-bottom: 12px;
        }
    }
    .db-cad {
        padding: 20px;
        border-top: 1px solid #E6E6E6;
        font-size: 14px;
        color: #808080;
        img {
            width: 26px;
            height: 22px;
        }
        .text {
            font-size: 12px;
            color: #B3B3B3;
            margin: 0 10px;
        }
    }
}
.detail-right {
   width: 320px;
   .dr-info {
       padding: 20px;
       background: #fff;
       border: 1px solid #E6E6E6;
       position: relative;
       margin-bottom: 20px;
        >div {
            font-size: 14px;
            color: #808080;
            line-height: 20px;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
            >span {
                color: #4D4D4D;
            }
        }
        .price {
            font-size: 18px;
            color: #F66F6A;
        }
        .contract_view {
            position: absolute;
            top: 10px; right: 20px;
        }
   }
   .dr-company {
       padding: 20px;
       background: #fff;
       border: 1px solid #E6E6E6;
       position: relative;
       margin-bottom: 20px;
       .drc-left {
           width: 32px;
           height: 32px;
           border-radius: 50%;
           overflow: hidden;
           margin-right: 12px;
           img {
               width: 100%;
               height: 100%;
           }
       }
       .name {
           font-size: 14px;
           color: #4D4D4D;
           line-height: 20px;
           margin-bottom: 12px;
       }
       .label-wrap {
            margin-bottom: 12px;
            label {
                padding: 0 8px;
                height:24px;
                line-height: 24px;
                border-radius:12px;
                margin-right: 12px;
                &.color-1 {
                    color: #F66F6A;
                    background:linear-gradient(180deg,rgba(255,178,150,0.2) 0%,rgba(253,136,149,0.2) 100%);
                }
                &.color-2 {
                    color: #2BBAAE;
                    background:linear-gradient(180deg,rgba(70,184,165,0.2) 0%,rgba(154,235,193,0.2) 100%);
                }
            }
        }
   }
   .dr-recommend {
        padding: 20px;
        background: #fff;
        border: 1px solid #E6E6E6;
        position: relative;
        margin-bottom: 20px;
        .drr-head {
            font-size: 18px;
            color: #292929;
            margin-bottom: 18px;
            img {
                width: 25px;
                height: 25px;
                margin-right: 6px;
            }
        }
        .drr-list .list-item {
            background: #FAFAFA;
            border: 1px solid #E6E6E6;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
            .list-item-img {
                width: 100px;
                height: 100px;
                margin-right: 12px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .list-item-info {
                padding: 16px 0;
                .name {
                   font-size: 16px;
                   color: #292929;
                   margin-bottom: 8px;
                }
                .cat {
                    font-size: 12px;
                    color: #B3B3B3;
                    line-height: 16px;
                }
            }
        }
   }
}
</style>